<template>
    <div class="setmeal">
      <joymew-header></joymew-header>
      <div class="banner">
      </div>
      <div class="meals">
        <div class="meal-item" v-for="meal in mealList" :key="meal.meal_title">
          <div class="meal-banner">
            <img :src="meal.src" alt="嗨喵悦动,嗨喵互动,嗨喵大放价">
            <div class="meal-banner-label"></div>
          </div>
          <div class="meal-title">{{ meal.meal_title }}</div>
          <div class="meal-content">{{ meal.meal_content }}</div>
          <div class="meal-labels">
            <div class="meal-label" v-for="label in meal.meal_label" :key="label.value">{{ label.name }}</div>
          </div>
          <div class="meal-footer">
            <div class="meal-price">
              <div class="now-price">￥<span>{{meal.meal_now_dic}}</span>/场</div>
              <div class="org-price">{{ `原价￥${meal.meal_org_price}/场` }}</div>
            </div>
            <div @click="handleBuy" class="meal-buy">立即抢购</div>
          </div>
        </div>
      </div>
      <div class="em">
        <img class="em-banner" src="@/assets/setMeal/em-title.png" alt="嗨喵悦动,嗨喵互动,嗨喵婚礼现场">
        <div class="em-items">
          <div class="em-item">
            <img src="../assets/setMeal/em1.png" alt="嗨喵悦动,嗨喵互动,嗨喵婚礼现场">
            <span class="em-label">2021年杭州婚礼现场</span>
          </div>
          <div class="em-item">
            <img src="../assets/setMeal/em2.png" alt="嗨喵悦动,嗨喵互动,嗨喵婚礼现场">
            <span class="em-label">2022嗨喵上海婚礼现场</span>
          </div>
          <div class="em-item">
            <img src="../assets/setMeal/em3.png" alt="嗨喵悦动,嗨喵互动,嗨喵婚礼现场">
            <span class="em-label">2023宁波婚礼现场</span>
          </div>
        </div>
      </div>
      <div class="service">
        <div class="service-banner">专业服务</div>
        <div class="service-items">
          <div class="service-item"><img src="@/assets/setMeal/service1.png" alt="嗨喵悦动,嗨喵互动,嗨喵服务"></div>
          <div class="service-item"><img src="@/assets/setMeal/service2.png" alt="嗨喵悦动,嗨喵互动,嗨喵服务"></div>
          <div class="service-item"><img src="@/assets/setMeal/service3.png" alt="嗨喵悦动,嗨喵互动,嗨喵服务"></div>
        </div>
      </div>
      <my-footer></my-footer>
      <div @click="showQrcode = false" v-show="showQrcode" class="qrcode-popup">
        <div @click.stop class="content">
          <img src="@/assets/image/qrcode.png" alt="嗨喵悦动,嗨喵互动,客服微信">
          <span>添加客服微信，领取优惠</span>
        </div>
      </div>
    </div>
  </template>
  <script>
  import joymewHeader from '@/components/layout/joymewHeader.vue';
  import myFooter from '@/components/myFooter.vue';
  
  export default {
    name: 'setmeal',
    components: {
      joymewHeader,
      myFooter,
    },
    methods: {
      handleBuy() {
        this.showQrcode = true;
      },
    },
    mounted() {
    },
    data() {
      return {
        showQrcode: false,
        mealList: [
          {
            meal_title: '宏包套餐',
            meal_content: '婚礼大屏宏包雨，新颖又惊喜',
            src: 'https://www.hudongmiao.com/img/shakeRedEnvelopes.c2f9093a.gif',
            meal_label: [
              {
                name: '摇宏包',
                value: '1',
              },
              {
                name: '点宏包',
                value: '2',
              },
              {
                name: '宏包墙',
                value: '3',
              },
              {
                name: '开宝箱',
                value: '4',
              },
            ],
            meal_org_price: '488',
            meal_now_dic: '288',
          },
          {
            meal_title: '抽奖套餐',
            meal_content: '炫酷抽奖，高端科技感',
            src: 'https://www.hudongmiao.com/img/giantScreenLottery.12f96f7b.gif',
            meal_label: [
              {
                name: '3D抽奖',
                value: '5',
              },
              {
                name: '巨幕抽奖',
                value: '6',
              },
              {
                name: '图片抽奖',
                value: '7',
              },
              {
                name: '名单抽奖',
                value: '8',
              },
            ],
            meal_org_price: '488',
            meal_now_dic: '288',
          },
          {
            meal_title: '游戏套餐',
            meal_content: '婚礼玩游戏，互动更有趣',
            src: 'https://www.hudongmiao.com/img/guessMovie.418f5b16.gif',
            meal_label: [
              {
                name: '扭一扭',
                value: '9',
              },
              {
                name: '转一转',
                value: '10',
              },
              {
                name: '猜成语',
                value: '11',
              },
              {
                name: '猜明星',
                value: '12',
              },
            ],
            meal_org_price: '488',
            meal_now_dic: '288',
          },
          {
            meal_title: '经典套餐',
            meal_content: '经典功能，创造十足仪式感',
            src: 'https://www.hudongmiao.com/hm-customer/static/img/giftAllFree1.a73aef91.gif',
            meal_label: [
              {
                name: '3D签到',
                value: '13',
              },
              {
                name: '祝福包场',
                value: '14',
              },
              {
                name: '宏包墙',
                value: '3',
              },
              {
                name: '抽奖',
                value: '15',
              },
            ],
            meal_org_price: '688',
            meal_now_dic: '388',
          },
          {
            meal_title: '3D签到套餐',
            meal_content: '自定义签到，酷炫科技感',
            src: 'https://www.hudongmiao.com/img/dargonFlyInSky.a39fc9f8.gif',
            meal_label: [
              {
                name: '摇宏包',
                value: '16',
              },
              {
                name: '点宏包',
                value: '13',
              },
              {
                name: '宏包墙',
                value: '17',
              },
              {
                name: '开宝箱',
                value: '4',
              },
            ],
            meal_org_price: '688',
            meal_now_dic: '388',
          },
          {
            meal_title: '尊享套餐',
            meal_content: '专属客服服务，涵盖全部功能',
            src: 'https://www.hudongmiao.com/hm-customer/static/img/giftAllFree2.a6ad565d.gif',
            meal_label: [
              {
                name: '摇宏包',
                value: '18',
              },
              {
                name: '点宏包',
                value: '19',
              },
              {
                name: '宏包墙',
                value: '20',
              },
              {
                name: '开宏包',
                value: '21',
              },
            ],
            meal_org_price: '1288',
            meal_now_dic: '888',
          },
        ],
      };
    },
  };
  </script>
  <style lang="less" scoped>
  // ::v-deep .header {
  //   width: 100%;
  
  //   box-sizing: border-box;
  //   // position: absolute;
  //   position: absolute;
  //   background-color: transparent ;
  //   top: 0;
  //   z-index: 10;
  //   // .el-menu-item .weddingItem {
  //   //   &:hover {
  //   //     color: #FFCC4F !important;
  //   //   }
  //   // }
  //   .el-submenu__title:hover {
  //     .weddingItem,.headerStyle {
  //       color: #FFCC4F !important;;
  //     }
  //     .el-submenu__icon-arrow {
  //       color: #FFCC4F !important;
  //     }
  //   }
  //   .headerStyle:hover {
  //     color
  //   }
  //   .middle ul {
  //     border: 0 !important;
  
  //     .weddingItem {
  //       color: white;
  //       font-weight: normal;
  //       transition: color .3s;
  //       // &:hover {
  //       //   color: #FFCC4F !important;
  
  //       // }
  
  //     }
  //     .headerStyle {
  //       color: white;
  //       font-weight: normal;
  //       transition: color .3s;
  //       // &:hover {
  //       //   color: #FFCC4F !important;
  
  //       // }
  
  //     }
  //     .el-submenu__icon-arrow {
  //       color: white !important;
  //     }
  
  //   }
  //   .right {
  //     .login {
  //       color: white !important;
  //     }
  
  //     .register {
  //       color: #FFCC4F !important;
  //       background: white !important;
  //     }
  
  //   }
  // }
  .qrcode-popup {
    // backdrop-filter: blur(7.5px);
    display: flex;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    // background-color: red;
    z-index: 100;
    justify-content: center;
    align-items: center;
  
    .content {
      transform: scale(.8);
      filter: drop-shadow(0 0 7.5px rgba(0,0,0,0.25));
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 32.0025px;
      padding: 33px;
      background-color: #fff;
      border-radius: 12px;
      img {
        width: 432.99750000000006px;
        height: 431.00249999999994px;
  
      }
    }
  }
  .setmeal {
    img {
      -webkit-user-drag: none;
    }
    .banner {
      width: 100%;
      height: 672px;
      display: flex;
      flex-direction: column;
      align-items: center;
      background-image: url('~@/assets/setMeal/snake-banner.png');
      background-size: 100% 100%;
      position: relative;
      z-index: 2;
    }
  
    .meals {
      width: 100%;
      margin-top: -22.5px;
      padding: 0 290.25px 63.75px 290.25px;
      box-sizing: border-box;
      display: grid;
      grid-template-columns: repeat(3, min-content);
      justify-content: center;
      row-gap: 39.9975px;
      column-gap: 39.9975px;
      background-color: #E94232;
      z-index: -1;
  
      .meal-item {
        width: 260.25px;
        height: 315.75px;
        margin-top: 39px;
        padding: 12px;
        box-sizing: border-box;
        background-color: #FFFFFF;
        border: 2.25px solid #FFE596;
        z-index: 3;
  
        &:nth-of-type(-n + 3) {
          margin-top: 0;
        }
  
        .meal-banner {
          width: 100%;
          height: 135.75px;
          margin-bottom: 15.75px;
          background-color: #FFD0D0;
          position: relative;
  
          .meal-banner-label {
            width: 48px;
            height: 17.25px;
            background-image: url('../assets/setMeal/meal-banner-label.png');
            background-size: 100% 100%;
            position: absolute;
            bottom: 0;
            right: 11.25px;
          }
        }
  
        .meal-title {
          margin-bottom: 3.75px;
          font-size: 20.25px;
          font-weight: bold;
        }
  
        .meal-content {
          margin-bottom: 9.75px;
          font-size: 14.25px;
        }
  
        .meal-labels {
          width: 100%;
          height: 18px;
          margin-bottom: 9.75px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
  
          .meal-label {
            white-space: nowrap;
            // width: 42px;
            padding: 0.75px;
            height: 100%;
            margin-right: 8.25px;
            background-color: #FFF1F2;
            border: 0.75px solid #FFCACE;
            border-radius: 2.25px;
            color: #FF2737;
            text-align: center;
            line-height: 18px;
  
            &:last-of-type {
              margin-right: 0;
            }
          }
        }
  
        .meal-footer {
          width: 100%;
          height: 49.5px;
          padding: 0 3.75px;
          box-sizing: border-box;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .meal-price {
            .now-price {
              color: #FF2B3A;
              font-size: 14.25px;
              span {
                font-weight: bold;
                font-size: 24px;
              }
            }
            .org-price {
              font-size: 12px;
              color: #999999;
            }
          }
  
          .meal-buy {
            width: 102.75px;
            height: 38.25px;
            background: linear-gradient(to bottom, #FF9E9D, #FF5F72);
            border-radius: 27.75px;
            color: #FFFFFF;
            font-size: 15.75px;
            line-height: 38.25px;
            text-align: center;
            cursor: pointer;
            transition: all .5s;
            &:hover {
              transform: scale(1.1);
            }
          }
        }
      }
    }
    .em {
      width: 100%;
      padding: 63.75px 120px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: #fff;
      .em-banner {
        width: 270.75px;
        height: 99.75px;
        margin-bottom: 39.75px;
      }
      .em-items {
        width: 100%;
        height: 290.25px;
        display: flex;
        justify-content: center;
        align-items: center;
        .em-item {
          margin: 0 13.5px;
          width: 381.75px;
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          background-color: #FFFFFF;
          cursor: default;
          img {
            width: 100%;
            height: 215.25px;
            margin: 0;
          }
          .em-label {
            width: 100%;
            height: 75px;
            color: #333333;
            font-size: 20.25px;
            text-align: center;
            line-height: 75px;
          }
        }
      }
    }
    .service {
      width: 100%;
      padding: 63.75px 120px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: #E94232;
      .service-banner {
        width: 263.25px;
        height: 92.25px;
        margin-bottom: 48px;
        box-sizing: border-box;
        border: 2.25px solid #FFFFFF;
        border-radius: 57px;
        color: #FFFFFF;
        font-size: 39.75px;
        font-weight: bold;
        line-height: 92.25px;
        text-align: center;
        cursor: none;
      }
      .service-items {
        width: 100%;
        height: 247.5px;
        display: flex;
        // justify-content: space-between;
        justify-content: center;
        
        align-items: center;
        .service-item {
          margin: 0 13.5px;
          width: 381.75px;
          height: 100%;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    .others {
      width: 100%;
      padding: 32.25px 150px;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      background-color: #FAF9F8;
      dt {
        margin-bottom: 9.75px;
        color: #333333;
        font-size: 18px;
        font-weight: bold;
        line-height: 26.25px;
        cursor: default;
      }
      dd {
        font-size: 15px;
        margin-bottom: 6.75px;
        cursor: pointer;
        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
    .footer {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: #FFFFFF;
      .footer-logo {
        width: 280.5px;
        height: 138.75px;
        margin-top: 39.75px;
        margin-bottom: 40.5px;
      }
      .cmp-info {
        width: 100%;
        margin-bottom: 18.75px;
        color: #FFCC4F;
        font-size: 15px;
        text-align: center;
      }
      .other-info {
        width: 100%;
        margin-bottom: 59.25px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: default;
        img {
          width: 22.5px;
          height: 22.5px;
          margin-right: 6px;
        }
        span {
          font-size: 15px;
          &:first-of-type {
            margin-right: 36px;
          }
        }
      }
    }
  }</style>
  