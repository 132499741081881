import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'newhome',
    component: () => import('../views/newhome.vue'),
    // meta: {
    //   title: '嗨喵悦动 - 嗨喵悦动首页',
    // },
  },
  {
    path: '/wxt',
    name: 'wxt',
    component: () => import('@/views/wxt.vue'),
    // meta: {
    //   title: '嗨喵悦动 - 微信扫码',
    // },
  },
  {
    path: '/statement',
    name: 'statement',
    component: () => import('../views/statement.vue'),
    // meta: {
    //   title: '嗨喵悦动 - 法律声明',
    // },
  },
  {
    path: '/partner',
    name: 'partner',
    component: () => import('../views/partner.vue'),
    // meta: {
    //   title: '嗨喵悦动 - 嗨喵合伙人',
    // },
  },
  {
    path: '/join',
    name: 'join',
    component: () => import('../views/join.vue'),
    // meta: {
    //   title: '嗨喵悦动 - 加入我们',
    // },
  },
  {
    path: '/advertisement',
    name: 'advertisement',
    component: () => import('../views/advertisement.vue'),
    // meta: {
    //   title: '嗨喵悦动 - 广告投放',
    // },
  },
  {
    path: '/company',
    name: 'company',
    component: () => import('../views/company.vue'),
    // meta: {
    //   title: '嗨喵悦动 - 公司简介',
    // },
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('../views/contact.vue'),
    // meta: {
    //   title: '嗨喵悦动 - 联系我们',
    // },
  },
  {
    path: '/introduce',
    name: 'introduce',
    component: () => import('../views/introduce.vue'),
    // meta: {
    //   title: '嗨喵悦动 - 产品介绍',
    // },
  },
  {
    path: '/service',
    name: 'service',
    component: () => import('../views/service.vue'),
    // meta: {
    //   title: '嗨喵悦动 - 用户协议',
    // },
  },
  {
    path: '/us',
    name: 'us',
    component: () => import('../views/us.vue'),
    // meta: {
    //   title: '嗨喵悦动 - 关于我们',
    // },
  },
  {
    path: '/phoneHome',
    name: 'phoneHome',
    component: () => import('../views/phone/home.vue'),
    // meta: {
    //   title: '嗨喵悦动 - 手机',
    // },
  },
  {
    path: '/phoneRegister',
    name: 'phoneRegister',
    component: () => import('../views/phone/register.vue'),
    // meta: {
    //   title: '嗨喵悦动 - 手机注册',
    // },
  },
  {
    path: '/phoneLogin',
    name: 'phoneLogin',
    component: () => import('../views/phone/login.vue'),
    // meta: {
    //   title: '嗨喵悦动 - 手机登录',
    // },
  },
  {
    path: '/phoneMain',
    name: 'phoneMain',
    component: () => import('../views/phone/main.vue'),
    // meta: {
    //   title: '嗨喵悦动 - 手机主页',
    // },
  },
  {
    path: '/phoneAnnualMeeting',
    name: 'phoneAnnualMeeting',
    component: () => import('../views/phone/phoneAnnualMeeting.vue'),
  },
  {
    path: '/setmeal',
    name: 'setmeal',
    component: () => import('../views/setmeal.vue'),
    // meta: {
    //   title: '嗨喵悦动 - 婚礼套餐',
    // },
  },
  {
    path: '/setmealSnake',
    name: 'setmealSnake',
    component: () => import('../views/setmealSnake.vue'),
    // meta: {
    //   title: '嗨喵悦动 - 婚礼套餐',
    // },
  },
  {
    path: '/app',
    name: 'appWebSite',
    component: () => import('../views/appWebSite.vue'),
  },

  {
    path: '/mentor',
    name: 'mentor',
    component: () => import('../views/mentor.vue'),
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
